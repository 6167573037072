<template>
  <nav>
    <div class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow-sm">
      <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <NuxtLink to="/">
            <nuxt-img src="/img/logo.png" class="h-16 p-1" alt="Jú Peixoto Doceria" />
          </NuxtLink>
          <div class="hidden sm:block">
            <button class="cursor-pointer text-xl leading-none px-3 py-1 bg-transparent block lg:hidden" type="button"
              @click="setNavbarOpen" name="btn-navbar" aria-label="Clique para abrir o menu"
              title="Clique para abrir o menu">
              <MenuLineIcon class="w-6 h-6 fill-current text-ber" />
            </button>
          </div>
        </div>
        <div class="flex-grow items-center mt-4 sm:mt-0 lg:flex" :class="[navbarOpen ? 'block' : 'hidden']">
          <ul class="flex flex-col items-center lg:flex-row list-none lg:ml-auto gap-4">
            <li class="flex items-center hover:text-pink-jpd-400" @click="setNavbarOpen">
              <NuxtLink to="/" exact-active-class="text-pink-jpd-400">Home</NuxtLink>
            </li>
            <li class="flex items-center hover:text-pink-jpd-400" @click="setNavbarOpen">
              <NuxtLink to="/products" exact-active-class="text-pink-jpd-400">Produtos</NuxtLink>
            </li>
            <li class="flex items-center">
              <UserDropdown :navbarOpen="navbarOpenDropdown" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <nav class="md:hidden">
    <div class="fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200">
      <div class="flex flex-row h-full mx-auto justify-around font-medium">
        <NuxtLink
          to="/"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          :class="isActive('/')"
        >
          <HomeIcon class="w-5 h-5 mb-2 fill-current" />
          <span class="text-sm">Home</span>
        </NuxtLink>
        <NuxtLink
          to="/products"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          :class="isActive('/products')"
        >
          <ProductIcon class="w-5 h-5 mb-2 fill-current" />
          <span class="text-sm">Produtos</span>
        </NuxtLink>
        <NuxtLink
          to="/user/management/"
          class="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group"
          :class="isActive('/user/management/')"
        >
          <UserIcon class="w-5 h-5 mb-2 fill-current" />
          <span class="text-sm">Perfil</span>
        </NuxtLink>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useRoute } from "vue-router";
import MenuLineIcon from "../../assets/svg/menu-line.svg";
import HomeIcon from "../../assets/svg/home-line.svg";
import ProductIcon from "../../assets/svg/shopping-bag-3-line.svg";
import UserIcon from "../../assets/svg/user-line.svg";

const navbarOpen = ref(false);
// Efetua processo de ocultação do menu ao efetuar click nos links do dropdown
const navbarOpenDropdown = reactive({ visible: navbarOpen.value });

// Força status do menu para ser fechado ao clicar nos links
watch(navbarOpenDropdown, () => {
  setNavbarOpen();
});

function setNavbarOpen() {
  navbarOpen.value = !navbarOpen.value;
}

// Identifica a rota ativa
const route = useRoute();
function isActive(path) {
  return route.path === path ? "text-pink-jpd-400 fill-pink-jpd-400" : "text-gray-500 fill-gray-500";
}
</script>

