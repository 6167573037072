<template>
  <footer class="w-full bg-blueGray-100 pt-10 pb-6">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <NuxtLink to="/">
            <nuxt-img src="/img/logo.png" class="max-h-20 p-1 mx-auto sm:mx-0" alt="Jú Peixoto Doceria" />
          </NuxtLink>

          <div class="mt-6 lg:mb-0 mb-6">
            <p class="text-lg mt-0 mb-2 text-gray-900">
              Nossas redes sociais
            </p>
            <NuxtLink to="https://api.whatsapp.com/send?l=pt&phone=5522999966823" target="_blank">
              <button
                class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-whatsapp-footer" aria-label="Clique e acesse o nosso WhatsApp"
                title="Clique e acesse o nosso WhatsApp">
                <WhatsAppIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>
            <NuxtLink to="https://www.instagram.com/jupeixotodoceria/" target="_blank">
              <button
                class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-instagram-footer" aria-label="Clique e acesse o nosso Instagram"
                title="Clique e acesse o nosso Instagram">
                <InstagramIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>
            <NuxtLink to=" https://goo.gl/maps/MRWQpJuVjEZFMQkD8" target="_blank">
              <button
                class="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-map-footer" aria-label="Clique e acesse a localização da nossa loja"
                title="Clique e acesse a localização da nossa loja">
                <MapPinIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>

            <NuxtLink to="https://www.facebook.com/docesdajucf/" target="_blank">
              <button
                class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-facebook-footer" aria-label="Clique e acesse o nosso Facebook"
                title="Clique e acesse o nosso Facebook">
                <FacebookIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>
            <NuxtLink to="https://play.google.com/store/apps/details?id=com.cdg.JuPeixotoDoceria" target="_blank">
              <button
                class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-facebook-footer" aria-label="Clique e acesse o nosso Facebook"
                title="Clique e acesse o nosso Facebook">
                <GooglePlayIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>
            <NuxtLink to="https://apps.apple.com/br/app/j%C3%BA-peixoto-doceria/id6739762235" target="_blank">
              <button
                class="bg-white text-black shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button" name="btn-facebook-footer" aria-label="Clique e acesse o nosso Facebook"
                title="Clique e acesse o nosso Facebook">
                <AppleStoreIcon class="w-7 h-7 fill-current inline" />
              </button>
            </NuxtLink>
          </div>
        </div>
        <div class="w-full lg:w-6/12">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <span class="block uppercase text-gray-900 text-sm font-bold mb-2">
                Links
              </span>
              <ul class="list-unstyled">
                <li>
                  <NuxtLink class="text-gray-900 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    to="/products">
                    Produtos
                  </NuxtLink>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              <span class="block uppercase text-gray-900 text-sm font-bold mb-2">
                Outros
              </span>
              <ul class="list-unstyled">
                <li>
                  <NuxtLink class="text-gray-900 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    to="/terms-and-conditions-of-use">
                    Termos e condições de uso
                  </NuxtLink>
                </li>
                <li>
                  <NuxtLink class="text-gray-900 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                    to="/privacy-policy">
                    Políticas de privacidade
                  </NuxtLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-gray-200" />
      <div class="flex flex-wrap items-center md:justify-between justify-center">
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-gray-900 font-semibold py-1">
            {{ date }} |
            <NuxtLink to="/" class="text-gray-900 font-bold hover:text-pink-jpd-400">
              Jú Peixoto Doceria®
            </NuxtLink> <br /> <span>R. Nicanor Pereira Couto, 39 - Vila Nova, Cabo Frio - RJ, 28907-390</span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import WhatsAppIcon from '../../assets/svg/whatsapp-line.svg?component';
import FacebookIcon from '../../assets/svg/facebook-circle-line.svg?component';
import InstagramIcon from '../../assets/svg/instagram-line.svg?component';
import MapPinIcon from '../../assets/svg/map-pin-line.svg?component';
import GooglePlayIcon from '../assets/svg/playstore-color.svg?component';
import AppleStoreIcon from '../assets/svg/apple-store-black.svg?component';
const date = new Date().getFullYear();
</script>
