import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "aria-label": "Google Play",
  viewBox: "0 0 512 512"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<rect width=\"512\" height=\"512\" fill=\"#fff\" rx=\"15%\"></rect><clipPath id=\"a\"><path d=\"M465 239 169 69c-15-9-35 2-35 20v334c0 18 20 29 35 20l296-171c13-7 13-25 0-33\"></path></clipPath><g clip-path=\"url(#a)\" transform=\"translate(-10 17)scale(.93324)\"><path fill=\"#0e7\" d=\"M20 53h400v355\"></path><path fill=\"#0df\" d=\"M512 444H98V30\"></path><path fill=\"#f34\" d=\"M399 181v322H76\"></path><path fill=\"#fd0\" d=\"m324 256 180 180V76\"></path></g>", 3)
  ])))
}
export default { render: render }